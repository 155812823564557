var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('Header',{staticClass:"header"},[_c('Breadcrumb'),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('Button',{staticClass:"add-btn",staticStyle:{"margin-right":"20px","background-color":"#00A0E9"},attrs:{"type":"info","to":"/sys_manage/threshold/add_threshold"}},[_vm._v("添加")]),(_vm.idArr.length > 0)?_c('Poptip',{attrs:{"confirm":"","transfer":"","title":"确定要批量删除吗?"},on:{"on-ok":function($event){return _vm.deleteItem('more')}}},[_c('Button',{staticClass:"add-btn",staticStyle:{"margin-right":"20px"},attrs:{"type":"warning"}},[_vm._v("批量删除")])],1):_c('Button',{staticClass:"add-btn",staticStyle:{"margin-right":"20px"},attrs:{"type":"warning"},on:{"click":function($event){return _vm.deleteItem('more')}}},[_vm._v("批量删除")])],1)],1),_c('div',{staticClass:"content"},[_c('Table',{staticClass:"table-list",attrs:{"loading":_vm.loading,"columns":_vm.columns,"data":_vm.data.list,"no-data-text":"没有符合查询条件的数据"},scopedSlots:_vm._u([{key:"stationName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getAllName(row.stats))+" ")]}},{key:"thrType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.thrType === 1 ? '实况' : '预报')+" ")]}},{key:"state",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.state === 1 ? '启用' : '禁用')+" ")]}},{key:"addTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.addTime))+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticClass:"action-btn",attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.editThreshold(row)}}},[_vm._v("修改")]),_c('Poptip',{attrs:{"confirm":"","transfer":"","title":"确定要删除吗?"},on:{"on-ok":function($event){return _vm.deleteItem(row.id)}}},[(row.id!='1')?_c('Button',{staticClass:"action-btn",attrs:{"type":"warning","size":"small"}},[_vm._v("删除")]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"page-nav"},[_c('Page',{attrs:{"show-total":"","total":_vm.data.total,"current":_vm.pageNum,"page-size":_vm.pageSize},on:{"update:current":function($event){_vm.pageNum=$event},"on-change":_vm.changPageNum}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
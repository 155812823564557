






















































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import config from '@/utils/config'
const columns = [
    {
        type: 'selection',
        width: 50,
    },
    {
        title: '站点编号',
        key: 'stats',
    },
    {
        title: '站点名称',
        slot: 'stationName',
    },
    {
        title: '阈值要素',
        key: 'elementText',
    },
    {
        title: '阈值类型',
        slot: 'thrType',
    },
    {
        title: '最小值',
        key: 'minValue',
    },
    {
        title: '最大值',
        key: 'maxValue',
    },
    {
        title: '预警内容',
        key: 'content',
    },
    {
        title: '状态',
        slot: 'state',
    },
    {
        title: '更新时间',
        slot: 'addTime',
    },
    {
        title: '操作',
        slot: 'action',
        // width: 400
    },
]
export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: {
                total: 0,
                list: [],
            },
            formData: {},
            areaList: [],
            stationArray: [],
            idArr: [] as any,
        }
    },
    methods: {
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const params = { pageNum, pageSize }
            request.get('/api/respond/threshold/page', params)
            .then((data) => {
                this.data = data
                this.loading = false
            })
        },
        selectionALL(selection: any) {
            this.idArr = [] as any
            selection.map((e: any) => {
                this.idArr.push(e.id)
            })
        },
        deleteItem(id: string) {
            if (id === 'more') {
                if (this.idArr.length > 0) {
                    const isStr = this.idArr.join(',')
                    request.del(`/api/respond/threshold/batch/${isStr}`)
                    .then((res) => {
                        this.$Message.success('删除成功!')
                        this.getData()
                    }).catch((errorMsg) => {
                        this.$Message.error(errorMsg)
                    })
                } else {
                    this.$Message.warning('批量值不能为空')
                }
            } else {
                request.del(`/api/respond/threshold/${id}`)
                .then((res) => {
                    this.$Message.success('删除成功!')
                    this.getData()
                }).catch((errorMsg) => {
                    this.$Message.error(errorMsg)
                })
            }
        },
        editThreshold(value: any) {
            const query = {
                thrType: value.thrType,
            }
            // this.$router.push({ path: `/info_maintenance/warning_threshold/${value.id}`, query})
            this.$router.push({ path: `threshold/edit_threshold/${value.id}`, query})
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
        getAreaList() {
            return request.get('/api/sys/area/descendants', null)
            .then((data) => {
                const {current} = this.$store.state
                const arr: any = [...[{id: current.areaId, name: current.areaName}], ...data]
                this.areaList = arr
            })
        },
        getAreaName(value: any) {
            const current: any =  this.areaList.filter((e: any) => {
                if (e.id === value) {
                    return e
                }
            })
            return current[0].name
        },
        getStationList() {
            const params = {
                staLevels: '011,012,013,014',
            }
            // if (this.$store.state.current.areaId === config.defaultStationObj.adminCode) {
            //     params = {
            //         staLevels: '011,012,013',
            //     }
            // }
            return request.get('/cw/station_info/list', params)
            .then((data) => {
                this.stationArray = data
            })
        },
        getStationName(value: any) {
            const current: any =  this.stationArray.filter((e: any) => {
                if (e.station_id_c === value) {
                    return e
                }
            })
            return current[0].station_name
        },
        getAllName(value: any) {
            const stationIds = value.split(',')
            const stationNames: any = []
            stationIds.forEach((element: any ) => {
                stationNames.push(this.getStationName(element))
            })
            return stationNames.join(',')
        },
    },
    mounted() {
        Promise.all([
            this.getAreaList(),
            this.getStationList(),
        ]).then((e) => {
            this.getData()
        })
    },
    filters: {
        formatDate(value: any) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
    },
})
